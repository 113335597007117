.loadingButton {
  position: relative;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.circle-loader {
  position: absolute;
  border: 2px solid #ffffff;
  border-top: 2px solid rgba(68, 75, 104, 0.5);
  border-radius: 50%;
  width: 19px;
  height: 19px;
  margin: 0 auto;
  animation: spin 1s linear infinite;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

.invisible-text-to-maintain-width {
  opacity: 0;
}

.page-loading-indicator-container {
  height: 30vh;
}

.circle-loader--large {
  position: relative;
  top: 50%;
  left: unset;
  height: 50px;
  width: 50px;
}
