.Dashboard {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-image: url('../components/images/world-map.svg');
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rt-thead {
  font-size: 0.75em;
  color: #aaa;
}

.ReactTable .rt-thead .rt-th {
  border: none;
}

.rt-tr-group {
  padding: 10px 0;
  margin: 5px;
  border: 1px solid #fafafa;
  border-radius: 5px;
  box-shadow: 1px 1px 8px lightgrey;
}

.nav-header > div {
  display: inline-block;
  width: 48%;
}

.left-header {
  text-align: left;
}

.right-header {
  float: right;
  text-align: right;
}

.right-header li {
  display: inline-block;
  padding: 5px;
  text-align: center;
}

.hero {
  position: relative;
  min-height: 350px;
  background-color: #34415f;
  background-size: cover;
  background-attachment: fixed;
  background-position: -50%;
  background-image: url('../components/images/world-map.svg');
  margin: 0 -12.5% 3rem;
}

.hero-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, #aaa 0%, #000 80%);
  opacity: 0.2;
}

.call-to-action {
  padding: 5rem 0;
  width: 45%;
  margin: 0 auto;
  color: white;
  text-align: center;
  position: relative;

  .body-text {
    opacity: 0.7;
    margin-bottom: 1.5rem;
    line-height: 26px;
  }
}

.contact-button {
  background-color: #ffbb6f;
  color: #444;
  border-radius: 1px;
  width: 7em;
  margin: 0 auto;
  padding: 0.5em 0.5em;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.25s linear;
}

.contact-button:hover {
  background: #efa859;
  transition: all 0.25s linear;
}
