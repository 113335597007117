.pagination-bottom {
  .pagination-section-all {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: auto;
    z-index: 4;

    .disabled-button {
      opacity: 0.5;
    }

    .pagination-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;

      .Table__visiblePagesWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: auto;
      }

      .Table__nextPageWrapper {
        width: auto;
      }

      .Table__prevPageWrapper {
        width: auto;
      }

      .Table__pageButton {
        font-size: 15px;
        outline: none;
        border: none;
        background-color: transparent;
        cursor: pointer;
        width: auto;
        margin: 0 0.25rem;
      }

      .Table__pageButton:disabled {
        cursor: not-allowed;
        color: gray;
      }

      .Table__pageButton--active {
        padding: 5px 10px;
        background-color: #444b68;
        border-radius: 25px;
        color: white;
        font-weight: bold;
      }
    }

    .grid-item {
      font-size: 30px;
      text-align: center;
      align-items: center;
      width: 100%;
    }

    .styleSelect select {
      width: 5rem;
      padding: 5px;
      font-size: 16px;
      line-height: 1;
      border: 0;
      border-radius: 0;
      height: 34px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    .styleSelect {
      width: 100px;
      height: 34px;
      color: #7c8195;
      overflow: hidden;
      background: url('../table_pagination/down.svg') no-repeat right;
    }

    .line-sort--container {
      .line-sort {
        height: 2px;
        width: 50px;
        border-radius: 1px;
        background-color: #a1a5b3;
        margin-left: 50px;
        position: relative;
        z-index: 2;
      }
    }
  }
}
