.search-icon {
  padding: 0rem 0.5rem;
}

.search-line {
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background-color: #a1a5b3;
  margin-bottom: 3rem;
}
.search-bar {
  text-align: left;
  width: auto;
}

.search-input {
  height: 100%;
  width: 100%;
  color: #444b68;
  background-color: #f4f5f9;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  line-height: 25px;
  padding: 10px 0px;
  border: none;
}

.italic {
  font-style: italic;
}

.search-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
