/* Actual Table Formating */
.ReactTable * {
  display: inline-grid;
  text-decoration: none;
  color: #444b68;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 19px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;

  /* Individual TABLE ITEMS Hover action */
  .rt-tbody .rt-tr-group:hover {
    cursor: pointer;
    outline: 2px solid #dadbe1;
  }
}

.ReactTable {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  /* column header row */
  .rt-thead.-header {
    width: 100%;
    margin-bottom: 0rem;
    pointer-events: none;

    /* Table Header Column sort area */
    .rt-tr {
      display: flex;
      flex-direction: row;
      margin: 0 1.5rem;
      text-align: left;
      width: auto;

      .rt-th:focus {
        outline: none;
      }
    }
  }

  /* Individual TABLE ITEMS */
  .rt-tbody {
    padding-top: 2rem;

    /* Row styling will revert to default w/o */
    .rt-tr-group {
      align-items: center;
      text-align: left;
      padding: 1.5rem;
      width: auto;
      border-radius: 2px;
      background-color: #ffffff;
      box-shadow: 0 20px 40px 0 #e0eaf4;
      margin: 0 0 1rem 0;
      border: none;
    }
  }

  /* Centers the text in each row vertically */
  .rt-tr {
    align-items: center;
    display: inline-flex;
  }

  /* Loading and noData are a duplicate from defaults has to be here for them to load*/
  .-loading {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }

  .-loading > div {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    -webkit-transform: translateY(-52%);
    transform: translateY(-52%);
    -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .-loading.-active {
    opacity: 1;
    z-index: 2;
    pointer-events: all;
  }

  .-loading.-active > div {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }

  .rt-noData {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 2px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 20px;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
  }
}

/* Hides indication of a sort by column */
.ReactTable .rt-thead .rt-th.-sort-asc.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-sort-asc.-cursor-pointer,
.ReactTable .rt-thead .rt-th.-sort-desc.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-sort-desc.-cursor-pointer {
  box-shadow: unset;
  border: none;
  outline: none;
}

.default {
  margin: 1rem 0rem;
  .noData-card {
    width: 100%;
    border-radius: 20px;
    background-color: #dadbe1;
    box-shadow: 0 20px 40px 0 #e0eaf4;
    display: inline-block;
    .noData-content {
      padding: 46px 48px;
      text-align: center;
      color: #444b68;
      font-weight: 500;
    }
  }
}

.noData-card {
  width: 100%;
  height: 27rem;
  border-radius: 20px;
  background-color: #dadbe1;
  display: inline-block;
  position: relative;

  .noData-content {
    position: absolute;
    padding: 46px 48px;
    text-align: center;
    color: #444b68;
    font-weight: 500;
    top: 50%;
    right: 25%;
    left: 25%;
    transform: translateY(-50%);
  }
}

.icon-fave-chauffeur {
  width: unset;
  margin-left: 0.25rem;
  height: 14px;
  position: relative;
  top: 1px;

  g {
    fill: #444b68;
  }
}

.one-line,
.one-line * {
  width: unset;
  display: inline-block;
}
