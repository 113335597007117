.icon-and-input-lockup {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  height: 4rem;
  width: 100%;
  margin-bottom: 3rem;
  color: #7c8195;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: left;

  .input-lockup {
    margin-bottom: unset;
  }

  .icon {
    height: 60px;
    width: 60px;
    padding-right: 2rem;
    background-repeat: no-repeat;
  }

  .icon--pin {
    background-image: url('../components/images/pin.svg');
  }
  .icon--clock {
    background-image: url('../components/images/clock.svg');
  }

  .input-lockup__label {
    width: 28.1px;
    color: white;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.14px;
    line-height: 19px;
    text-align: center;
    text-shadow: 0 10px 10px 0 #1c2b4c;
    margin: 18px 0 0 17px;
  }
}
