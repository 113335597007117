.itinerary {
  font-size: 24px;
  max-width: 400px;
  width: 55%;

  .itinerary-content {
    font-size: 16px;

    .waypoint-list {
      span:last-of-type {
        border-left: 5px solid white;
      }

      .waypoint-content {
        position: relative;
        display: flex;
        margin-left: 10px;
        border-left: 5px solid #444b68;

        .section {
          margin-bottom: 5px;
        }

        .waypoint-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: 1px;
          text-transform: uppercase;
          margin-bottom: 4px;
        }

        .icon {
          margin-right: 0.25rem;
          position: relative;
          top: 1px;
        }

        .waypoint-text-container {
          flex-direction: column;
          margin-bottom: 1.75rem;
          padding-left: 2rem;

          .notes {
            margin-top: 0.75rem;
            font-weight: 400;
            max-width: 380px;
            font-size: 14px;
            line-height: 17px;

            .notes-header {
              color: #7c8195;
              font-size: 12px;
              letter-spacing: 0.86px;
              line-height: 14px;
            }
          }
        }

        .point-circle {
          position: absolute;
          background-color: #444b68;
          height: 22px;
          width: 22px;
          border-radius: 22px;
          color: white;
          margin-bottom: 1rem;
          left: -13px;
          top: -1px;

          .point-num {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.86px;
            line-height: 14px;
            padding-top: 4px;
            text-align: center;
          }
        }
      }
    }
  }
}
