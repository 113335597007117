.pickupHeader {
  .recommendation {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    color: #7c8195;
    line-height: 23px;
    margin-bottom: 3rem;
    width: 80%;
  }

  .header2 {
    font-size: 30px;
    margin-bottom: 1rem;
  }
}
