.invoice-modal {
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999999;

    .popupInvoice {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: white;
      border-radius: 20px;
      box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
      padding: 2rem;

      .popup-close {
        position: absolute;
        right: 0.5rem;
        top: 0.5em;
        padding: 1rem;
        cursor: pointer;
      }

      .popup-body {
        width: 100%;
        font-size: 24px;
        line-height: 24px;

        h3 {
          margin-block-start: unset;
          font-size: 24px;
          font-weight: normal;
        }

        p {
          font-size: 18px;

          small {
            color: #7c8195;
            font-size: 14px;
            line-height: 16px;

            a {
              color: #7c8195;
              cursor: default;
            }
          }

          span {
            font-weight: 500;
          }
        }
      }
      .popup-actions {
        text-align: right;

        .loadingButton,
        .submit {
          margin-bottom: 0;
          display: inline-block;
        }
      }
    }
  }
}

.contact-method {
  margin: 0 0 1rem 2rem;
  color: #444b68;
  font-family: Roboto;
  font-size: 18px;
  line-height: 24px;
}
