@import url('https://fonts.googleapis.com/css?family=Roboto');

.sign-in {
  text-align: center;
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;

  .title {
    width: 100%;
    color: #444b68;
    font-size: 36px;
    font-weight: 500;
    line-height: 42px;
    display: inline-block;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .card {
    width: 30rem;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 20px 40px 0 #e0eaf4;
    padding: 46px 48px;
    display: inline-block;

    .forgot-password {
      color: #7c8195;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0.7px;
      line-height: 16px;
    }

    .error {
      margin-bottom: 2rem;
    }
  }
}
