@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap);
.input-lockup {
  width: 100%;
  color: #7c8195;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: left;
  margin-bottom: 3rem;
  position: relative;
}
.input-lockup input,
.input-lockup textarea {
  width: 100%;
  color: #444b68;
  font-size: 18px;
  padding: 10px 0px;
  border: none;
  border-bottom: 2px solid transparent;
}
.input-lockup textarea {
  line-height: 24px;
  resize: none;
  font-family: Roboto, sans-serif;
}
.input-lockup input.underline,
.input-lockup textarea.underline {
  border-bottom: 2px solid #a1a5b3;
}
.input-lockup input::-webkit-input-placeholder, .input-lockup textarea::-webkit-input-placeholder {
  color: #a1a5b3;
  font-style: italic;
}
.input-lockup input:-ms-input-placeholder, .input-lockup textarea:-ms-input-placeholder {
  color: #a1a5b3;
  font-style: italic;
}
.input-lockup input::-ms-input-placeholder, .input-lockup textarea::-ms-input-placeholder {
  color: #a1a5b3;
  font-style: italic;
}
.input-lockup input::placeholder,
.input-lockup textarea::placeholder {
  color: #a1a5b3;
  font-style: italic;
}
.input-lockup input:focus,
.input-lockup select:focus,
.input-lockup textarea:focus,
.input-lockup button:focus {
  outline: none;
}
.input-lockup input:focus,
.input-lockup textarea:focus {
  border-bottom: 2px solid #444b68;
}
.input-lockup .passwordIconContainer {
  position: absolute;
  right: 0;
  top: 15px;
  line-height: 58px;
}
.loadingButton {
  position: relative;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.circle-loader {
  position: absolute;
  border: 2px solid #ffffff;
  border-top: 2px solid rgba(68, 75, 104, 0.5);
  border-radius: 50%;
  width: 19px;
  height: 19px;
  margin: 0 auto;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

.invisible-text-to-maintain-width {
  opacity: 0;
}

.page-loading-indicator-container {
  height: 30vh;
}

.circle-loader--large {
  position: relative;
  top: 50%;
  left: unset;
  height: 50px;
  width: 50px;
}
.sign-in {
  text-align: center;
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
}
.sign-in .title {
  width: 100%;
  color: #444b68;
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  display: inline-block;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.sign-in .card {
  width: 30rem;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 20px 40px 0 #e0eaf4;
  padding: 46px 48px;
  display: inline-block;
}
.sign-in .card .forgot-password {
  color: #7c8195;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.7px;
  line-height: 16px;
}
.sign-in .card .error {
  margin-bottom: 2rem;
}
.search-icon {
  padding: 0rem 0.5rem;
}

.search-line {
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background-color: #a1a5b3;
  margin-bottom: 3rem;
}

.search-bar {
  text-align: left;
  width: auto;
}

.search-input {
  height: 100%;
  width: 100%;
  color: #444b68;
  background-color: #f4f5f9;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  line-height: 25px;
  padding: 10px 0px;
  border: none;
}

.italic {
  font-style: italic;
}

.search-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
/* Actual Table Formating */
.ReactTable * {
  display: inline-grid;
  text-decoration: none;
  color: #444b68;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 19px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  /* Individual TABLE ITEMS Hover action */
}
.ReactTable * .rt-tbody .rt-tr-group:hover {
  cursor: pointer;
  outline: 2px solid #dadbe1;
}

.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;
  /* column header row */
  /* Individual TABLE ITEMS */
  /* Centers the text in each row vertically */
  /* Loading and noData are a duplicate from defaults has to be here for them to load*/
}
.ReactTable .rt-thead.-header {
  width: 100%;
  margin-bottom: 0rem;
  pointer-events: none;
  /* Table Header Column sort area */
}
.ReactTable .rt-thead.-header .rt-tr {
  display: flex;
  flex-direction: row;
  margin: 0 1.5rem;
  text-align: left;
  width: auto;
}
.ReactTable .rt-thead.-header .rt-tr .rt-th:focus {
  outline: none;
}
.ReactTable .rt-tbody {
  padding-top: 2rem;
  /* Row styling will revert to default w/o */
}
.ReactTable .rt-tbody .rt-tr-group {
  align-items: center;
  text-align: left;
  padding: 1.5rem;
  width: auto;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 20px 40px 0 #e0eaf4;
  margin: 0 0 1rem 0;
  border: none;
}
.ReactTable .rt-tr {
  align-items: center;
  display: inline-flex;
}
.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}
.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 2px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

/* Hides indication of a sort by column */
.ReactTable .rt-thead .rt-th.-sort-asc.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-sort-asc.-cursor-pointer,
.ReactTable .rt-thead .rt-th.-sort-desc.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-sort-desc.-cursor-pointer {
  box-shadow: unset;
  border: none;
  outline: none;
}

.default {
  margin: 1rem 0rem;
}
.default .noData-card {
  width: 100%;
  border-radius: 20px;
  background-color: #dadbe1;
  box-shadow: 0 20px 40px 0 #e0eaf4;
  display: inline-block;
}
.default .noData-card .noData-content {
  padding: 46px 48px;
  text-align: center;
  color: #444b68;
  font-weight: 500;
}

.noData-card {
  width: 100%;
  height: 27rem;
  border-radius: 20px;
  background-color: #dadbe1;
  display: inline-block;
  position: relative;
}
.noData-card .noData-content {
  position: absolute;
  padding: 46px 48px;
  text-align: center;
  color: #444b68;
  font-weight: 500;
  top: 50%;
  right: 25%;
  left: 25%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.icon-fave-chauffeur {
  width: unset;
  margin-left: 0.25rem;
  height: 14px;
  position: relative;
  top: 1px;
}
.icon-fave-chauffeur g {
  fill: #444b68;
}

.one-line,
.one-line * {
  width: unset;
  display: inline-block;
}
.invite-table .ReactTable .rt-table {
  width: 100%;
  overflow-x: hidden;
  /* Individual TABLE ITEMS */
}
.invite-table .ReactTable .rt-table .rt-thead {
  /* column header row */
  /* Table Header Column sort area */
}
.invite-table .ReactTable .rt-table .rt-thead .-header {
  width: auto;
  margin-bottom: 0rem;
  padding: 0;
}
.invite-table .ReactTable .rt-table .rt-thead .rt-tr {
  margin: 0;
  text-align: left;
}
.invite-table .ReactTable .rt-table .rt-tbody {
  /* Row styling will revert to default w/o */
}
.invite-table .ReactTable .rt-table .rt-tbody .rt-tr-group {
  overflow-x: hidden;
  height: 54px;
  width: auto;
  opacity: 0.5;
  border-radius: 2px;
  background-color: #ffffff;
  border: none;
  box-shadow: none;
  padding: 0;
  flex-direction: row;
  align-items: center;
  text-align: left;
  margin: 0;
  border-bottom: none;
  /* Individual TABLE ROWS */
}
.invite-table .ReactTable .rt-table .rt-tbody .rt-tr-group .-even {
  border-radius: 2px;
  border: none;
  height: 54px;
  border-radius: 2px;
  background: #f4f5f9;
  flex-direction: row;
  align-items: center;
  text-align: left;
  padding: 0 1rem;
}
.invite-table .ReactTable .rt-table .rt-tbody .rt-tr-group .-odd {
  background: #ffffff;
  border-radius: 2px;
  border-bottom: none;
  padding: 0 1rem;
}
.invite-table .ReactTable .pagination-bottom .pagination-section-all {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex;
  margin-left: 0;
  width: 100%;
}
.invite-table .ReactTable .pagination-bottom .pagination-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
}

/* Individual TABLE ITEMS Hover action */
.invite-table .ReactTable .rt-tbody .rt-tr-group:hover {
  cursor: pointer;
  box-shadow: none;
  outline: 2px solid #dadbe1;
}
.invoice-table .ReactTable .rt-table {
  width: 100%;
  /* Individual TABLE ITEMS */
}
.invoice-table .ReactTable .rt-table .rt-thead {
  /* column header row */
  /* Table Header Column sort area */
}
.invoice-table .ReactTable .rt-table .rt-thead .-header {
  width: 100%;
  margin-bottom: 0rem;
  padding: 0;
}
.invoice-table .ReactTable .rt-table .rt-thead .rt-tr {
  text-align: left;
  padding: 0 1.5rem;
  width: calc(100% - 3rem);
}
.invoice-table .ReactTable .rt-table .rt-tr {
  margin: 0;
  width: calc(100% - 3rem);
}
.invoice-table .ReactTable .rt-table .rt-tbody {
  /* Row styling will revert to default w/o */
}
.invoice-table .ReactTable .rt-table .rt-tbody .rt-tr-group {
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  box-shadow: none;
  padding: 0;
  flex-direction: row;
  align-items: center;
  text-align: left;
  margin: 0;
  border-bottom: none;
  width: 100%;
  /* Individual TABLE ROWS */
}
.invoice-table .ReactTable .rt-table .rt-tbody .rt-tr-group .-even {
  padding: 1rem 1.5rem;
  border-radius: 2px;
  border: none;
  border-radius: 2px;
  background: #f4f5f9;
  flex-direction: row;
  align-items: center;
  text-align: left;
  margin: 0;
}
.invoice-table .ReactTable .rt-table .rt-tbody .rt-tr-group .-odd {
  padding: 1rem 1.5rem;
  border-radius: 2px;
  border-bottom: none;
  margin: 0;
}
.invoice-table .ReactTable .pagination-bottom .pagination-section-all {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex;
  margin-left: 0;
  width: 100%;
}
.invoice-table .ReactTable .pagination-bottom .pagination-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
}

/* Individual TABLE ITEMS Hover action */
.invoice-table .ReactTable .rt-tbody .rt-tr-group:hover {
  cursor: pointer;
  box-shadow: none;
  outline: 2px solid #dadbe1;
  z-index: 2;
}
.pagination-bottom .pagination-section-all {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: auto;
  z-index: 4;
}
.pagination-bottom .pagination-section-all .disabled-button {
  opacity: 0.5;
}
.pagination-bottom .pagination-section-all .pagination-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
}
.pagination-bottom .pagination-section-all .pagination-section .Table__visiblePagesWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: auto;
}
.pagination-bottom .pagination-section-all .pagination-section .Table__nextPageWrapper {
  width: auto;
}
.pagination-bottom .pagination-section-all .pagination-section .Table__prevPageWrapper {
  width: auto;
}
.pagination-bottom .pagination-section-all .pagination-section .Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: auto;
  margin: 0 0.25rem;
}
.pagination-bottom .pagination-section-all .pagination-section .Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
}
.pagination-bottom .pagination-section-all .pagination-section .Table__pageButton--active {
  padding: 5px 10px;
  background-color: #444b68;
  border-radius: 25px;
  color: white;
  font-weight: bold;
}
.pagination-bottom .pagination-section-all .grid-item {
  font-size: 30px;
  text-align: center;
  align-items: center;
  width: 100%;
}
.pagination-bottom .pagination-section-all .styleSelect select {
  width: 5rem;
  padding: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 0;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.pagination-bottom .pagination-section-all .styleSelect {
  width: 100px;
  height: 34px;
  color: #7c8195;
  overflow: hidden;
  background: url(/static/media/down.5589bc1c.svg) no-repeat right;
}
.pagination-bottom .pagination-section-all .line-sort--container .line-sort {
  height: 2px;
  width: 50px;
  border-radius: 1px;
  background-color: #a1a5b3;
  margin-left: 50px;
  position: relative;
  z-index: 2;
}
.sorter {
  pointer-events: auto;
  cursor: pointer;
  max-height: 14px;
  max-width: 10px;
  vertical-align: middle;
  padding: 0.5rem;
}

.icon4table {
  margin-right: 0.5rem;
  vertical-align: middle;
  max-height: 24px;
  max-width: 24px;
}

.ride {
  opacity: 0.7;
  color: #444b68;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16px;
  display: inline-block;
  position: relative;
}

.not-available {
  background: #444b68;
  border-radius: 20px;
  top: 100%;
  height: 1px;
  width: 50px;
}

.status {
  display: flex;
  flex-direction: row;
}
.status .status-indicator {
  height: 10px;
  max-width: 10px;
  margin-top: 5px;
  margin-right: 8px;
  border-radius: 20px;
}
.status .blue {
  background: #4a90e2;
}
.status .red {
  background: #e45b5b;
}
.status .green {
  background: #5ea422;
}
.status .gray {
  background: #a1a5b3;
}

img.icon-ride-tracking-in-table {
  width: 11px;
  padding: 0 2px;
}

.rideId-column-content {
  display: flex;
}
.Dashboard {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-image: url(/static/media/world-map.d9131666.svg);
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.rt-thead {
  font-size: 0.75em;
  color: #aaa;
}

.ReactTable .rt-thead .rt-th {
  border: none;
}

.rt-tr-group {
  padding: 10px 0;
  margin: 5px;
  border: 1px solid #fafafa;
  border-radius: 5px;
  box-shadow: 1px 1px 8px lightgrey;
}

.nav-header > div {
  display: inline-block;
  width: 48%;
}

.left-header {
  text-align: left;
}

.right-header {
  float: right;
  text-align: right;
}

.right-header li {
  display: inline-block;
  padding: 5px;
  text-align: center;
}

.hero {
  position: relative;
  min-height: 350px;
  background-color: #34415f;
  background-size: cover;
  background-attachment: fixed;
  background-position: -50%;
  background-image: url(/static/media/world-map.d9131666.svg);
  margin: 0 -12.5% 3rem;
}

.hero-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#aaa), color-stop(80%, #000));
  background: linear-gradient(to bottom, #aaa 0%, #000 80%);
  opacity: 0.2;
}

.call-to-action {
  padding: 5rem 0;
  width: 45%;
  margin: 0 auto;
  color: white;
  text-align: center;
  position: relative;
}
.call-to-action .body-text {
  opacity: 0.7;
  margin-bottom: 1.5rem;
  line-height: 26px;
}

.contact-button {
  background-color: #ffbb6f;
  color: #444;
  border-radius: 1px;
  width: 7em;
  margin: 0 auto;
  padding: 0.5em 0.5em;
  font-size: 1.5em;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.contact-button:hover {
  background: #efa859;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.popupContactFortis {
  color: #444b68;
  position: absolute;
  left: 50%;
  top: 45%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: white;
  border-radius: 20px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
  padding: 4rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
}
.popupContactFortis .popup-close {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  padding: 1rem;
}
.popupContactFortis .header {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  display: block;
  margin-bottom: 1rem;
}
.popupContactFortis .contact {
  display: inline-block;
  font-size: 18px;
}
.popupContactFortis .vertical-rule {
  display: inline-block;
  background: #dadbe1;
  width: 1px;
  height: 50px;
  margin: 0 1.5rem;
  position: relative;
  top: 20px;
}
.popupContactFortis .contact .icon {
  margin-right: 0.5rem;
  width: 25px;
  text-align: center;
}
.popupContactFortis .contact .icon--mail {
  position: relative;
  top: 2px;
}
.popupContactFortis .contact .icon--phone {
  position: relative;
  top: 5px;
}
.reservations-tabs {
  height: 100%;
  width: 100%;
  color: #444b68;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-top: 18px;
}

.header-row-container {
  display: flex;
  color: #444b68;
  font-family: Roboto, sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  margin-bottom: 3rem;
  text-align: left;
  width: auto;
  align-items: baseline;
}

.styleSelect select {
  width: 176px;
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  border: 0;
  border-radius: 0;
  height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.styleSelect {
  color: #7c8195;
  overflow: hidden;
  background: url(/static/media/down.5589bc1c.svg) no-repeat right;
}

.line-sort {
  height: 2px;
  width: 175px;
  border-radius: 1px;
  background-color: #a1a5b3;
  margin-left: 55px;
  position: relative;
  z-index: 2;
}

.status-dropdown {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  flex-direction: column-reverse;
  width: auto;
  margin-left: auto;
}

.status-section {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}
.rides-back {
  display: flex;
  flex-direction: row;
  color: #444b68;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 2rem;
  text-align: left;
}
.rides-back .arrow {
  padding-right: 0.5rem;
}

.ride-header .ride-header-info {
  margin-bottom: 1rem;
  display: inline-block;
}
.ride-header .ride-header-info .ride-title {
  font-size: 36px;
  font-weight: 500;
  line-height: 39px;
}
.ride-header .ride-header-info .reservation-status-warning {
  color: #e45b5e;
  font-weight: 600;
  background: none;
}
.ride-header .ride-header-info .ride-id-row {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: baseline;
}
.ride-header .ride-header-info .ride-id-row .reservation-status {
  font-size: 14px;
  font-weight: 400;
}
.ride-header .ride-status {
  top: -4px;
  position: relative;
  display: inline-block;
  align: right;
}
.ride-header .ride-status .ride-status__container {
  display: grid;
  grid-template-columns: auto 1fr;
  display: inline-block;
}
.ride-header .ride-status .ride-status__container:first-of-type {
  margin-right: 1rem;
}
.ride-header .ride-status .ride-status__label-and-status {
  display: inline-block;
}
.ride-header .ride-status .ride-status__label {
  display: inline-block;
}
.ride-header .ride-status .ride-status__status {
  display: inline-block;
  font-weight: 300;
}
.ride-header .ride-status .icon-checkmark {
  margin-right: 4px;
  opacity: 0.7;
  position: relative;
  top: 1px;
}
.ride-header .security-icon {
  height: 63.52px;
  width: 63.76px;
  margin-right: 1rem;
}
.text-content-collapsed {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.text-content-collapsed .text-gradient {
  position: absolute;
  top: 45px;
  height: 28px;
  width: 100%;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  border-radius: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.text-content-collapsed .text-gradient--rideNote {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffeedb), to(rgba(255, 238, 219, 0)));
  background: linear-gradient(180deg, #ffeedb 0%, rgba(255, 238, 219, 0) 100%);
}

.text-content {
  height: 100%;
}
.overview {
  font-size: 18px;
  line-height: 24px;
  margin-right: 2rem;
  min-width: 50px;
  width: 45%;
}
.overview .details {
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
}
.overview .details .detail-icon {
  margin-right: 1rem;
  min-width: 60px;
  height: 60px;
  width: 60px;
}
.overview .details .overview__chauffeur-avatar-container {
  width: 60px;
  min-width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border: 2px solid #444b68;
  background: #f4f5f9;
}
.overview .details .overview__chauffeur-avatar {
  height: auto;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
}
.overview .details .detail .detail-header {
  color: #7c8195;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16px;
}
.overview .details .detail .detail-content {
  color: #444b68;
  font-family: Roboto;
}
.overview .details .detail .detail-extra {
  color: #a1a5b3;
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 1px;
  line-height: 16px;
}
.overview .details--top-align {
  align-items: start;
}
.overview .favorite-icon {
  margin-right: 0.25rem;
}
.overview .favorite-icon--favorited g {
  fill: #ffbb6f;
}
.overview .favorite-button-hover-container {
  background: #ecedef;
  font-size: 14px;
  line-height: 18px;
  position: absolute;
  top: 2rem;
  width: 16rem;
  z-index: 3;
  padding: 0.5rem;
  border-radius: 6px;
  box-shadow: 0 10px 20px 0 #c0c2c8;
}
.itinerary {
  font-size: 24px;
  max-width: 400px;
  width: 55%;
}
.itinerary .itinerary-content {
  font-size: 16px;
}
.itinerary .itinerary-content .waypoint-list span:last-of-type {
  border-left: 5px solid white;
}
.itinerary .itinerary-content .waypoint-list .waypoint-content {
  position: relative;
  display: flex;
  margin-left: 10px;
  border-left: 5px solid #444b68;
}
.itinerary .itinerary-content .waypoint-list .waypoint-content .section {
  margin-bottom: 5px;
}
.itinerary .itinerary-content .waypoint-list .waypoint-content .waypoint-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.itinerary .itinerary-content .waypoint-list .waypoint-content .icon {
  margin-right: 0.25rem;
  position: relative;
  top: 1px;
}
.itinerary .itinerary-content .waypoint-list .waypoint-content .waypoint-text-container {
  flex-direction: column;
  margin-bottom: 1.75rem;
  padding-left: 2rem;
}
.itinerary .itinerary-content .waypoint-list .waypoint-content .waypoint-text-container .notes {
  margin-top: 0.75rem;
  font-weight: 400;
  max-width: 380px;
  font-size: 14px;
  line-height: 17px;
}
.itinerary .itinerary-content .waypoint-list .waypoint-content .waypoint-text-container .notes .notes-header {
  color: #7c8195;
  font-size: 12px;
  letter-spacing: 0.86px;
  line-height: 14px;
}
.itinerary .itinerary-content .waypoint-list .waypoint-content .point-circle {
  position: absolute;
  background-color: #444b68;
  height: 22px;
  width: 22px;
  border-radius: 22px;
  color: white;
  margin-bottom: 1rem;
  left: -13px;
  top: -1px;
}
.itinerary .itinerary-content .waypoint-list .waypoint-content .point-circle .point-num {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.86px;
  line-height: 14px;
  padding-top: 4px;
  text-align: center;
}
.ride-content {
  display: flex;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 20px 40px 0 #e0eaf4;
  overflow: hidden;
  position: relative;
}
.ride-content .ride-content-left {
  padding: 2rem 0.75rem 2rem 3rem;
  flex: 2 1 auto;
  width: 45%;
}
.ride-content .ride-content-left .note {
  display: flex;
  background: rgba(255, 187, 111, 0.25);
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 2rem;
  font-size: 14px;
  line-height: 17px;
}
.ride-content .ride-content-left .note .note-icon {
  color: #444b68;
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
}
.ride-content .ride-content-left .ride-info {
  display: flex;
}
.ride-content .map-toggle {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
  left: 20px;
  cursor: pointer;
}
.ride-content .map-toggle .map-toggle__half {
  display: flex;
  background-color: white;
  height: 50%;
  width: 20px;
  flex: 2 1 auto;
}
.ride-content .map-toggle .map-toggle__half--top {
  margin-bottom: -20px;
  flex-direction: column-reverse;
}
.ride-content .map-toggle .map-toggle__half--bottom {
  margin-top: -20px;
}
.ride-content .map-toggle .map-toggle__handle {
  margin-left: 0.15rem;
  height: 63px;
  width: 4px;
  border-radius: 2px;
  background-color: #c6c9d1;
  z-index: 1;
}
.ride-content .map-toggle .map-toggle__arrow-cutout {
  border-color: transparent white;
  border-style: solid;
  border-width: 20px 0px 20px 20px;
}
.ride-content .subhead {
  font-size: 24px;
}
.ride-content .ride-details-show {
  width: 44px;
  z-index: 999999;
  cursor: pointer;
  height: calc(100vh - 330px);
  min-height: 400px;
  position: relative;
}
.ride-content .ride-details-show .vertical-text {
  position: relative;
  top: 50%;
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
          transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  color: #7c8195;
  font-size: 14px;
  letter-spacing: 1px;
  white-space: nowrap;
}
.ride-content .ride-details-show .arrow {
  position: absolute;
  right: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-color: transparent white;
  border-style: solid;
  border-width: 20px 0px 20px 20px;
}
.ride-content .Map {
  position: relative;
  flex: 2 1 auto;
  width: 40%;
  z-index: 1;
}

.ride-extras {
  display: flex;
  flex-direction: row;
}
.ride-extras .item-of-choice {
  width: 50%;
  border-radius: 20px;
  background-color: #ffffff;
  margin-right: 2rem;
  padding: 2rem 3rem;
}
.ride-extras .item-of-choice .item-of-choice-header {
  color: #444b68;
  font-family: Roboto;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 2rem;
}
.ride-extras .item-of-choice .item-of-choice-content {
  display: flex;
  flex-direction: row;
}
.ride-extras .item-of-choice .item-of-choice-content .item-of-choice-input {
  width: 30rem;
  color: #444b68;
  font-family: Roboto;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 2rem;
  margin-right: 5rem;
}
.ride-extras .item-of-choice .item-of-choice-content .item-of-choice-status {
  color: #444b68;
  font-family: Roboto;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 2rem;
}
.ride-extras .item-of-choice .item-of-choice-content .item-of-choice-status .request-status {
  color: #7c8195;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16px;
  margin-bottom: 1rem;
}
.ride-extras .item-of-choice .item-of-choice-content .item-of-choice-status .stage {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
  color: #444b68;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 23.5px;
}
.ride-extras .item-of-choice .item-of-choice-content .item-of-choice-status .stage .status-icon {
  box-sizing: border-box;
  height: 23.5px;
  width: 23.5px;
  border-radius: 20px;
  margin-right: 0.75rem;
}
.ride-extras .item-of-choice .item-of-choice-contact {
  color: #a1a5b3;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
}
.ride-extras .item-of-choice .item-of-choice-contact .handcrafting-desk {
  color: #444b68;
  text-decoration: underline;
}
.ride-extras .item-of-choice .item-request-btn {
  position: relative;
}
.ride-extras .item-of-choice .item-request-btn .btn-enabled {
  height: 42.62px;
  width: 10rem;
  border-radius: 6px;
  background-color: #444b68;
}
.ride-extras .item-of-choice .item-request-btn .btn-disabled {
  height: 42.62px;
  width: 10rem;
  border-radius: 6px;
  background-color: rgba(68, 75, 104, 0.5);
}
.ride-extras .item-of-choice .item-request-btn .btn {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.34px;
  line-height: 19px;
}
.ride-extras .check-in {
  height: 352px;
  width: 40%;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 2rem 3rem;
  position: relative;
}
.ride-extras .check-in .info-icon {
  position: relative;
  top: -150px;
  right: -100%;
}
.ride-extras .check-in .check-in-header {
  height: 28px;
  width: 156px;
  color: #444b68;
  font-family: Roboto;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 2rem;
}
.ride-extras .check-in .check-in-status {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
  color: #444b68;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 23.5px;
}
.ride-extras .check-in .check-in-status .status-icon {
  margin-right: 0.75rem;
}
.ride-extras .check-in .check-in-message {
  color: #444b68;
  font-size: 18px;
  line-height: 24px;
}

.security-icon {
  height: 63.52px;
  width: 63.76px;
  margin-right: 1rem;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999999;
}
.popup .popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  height: 188px;
  width: 363px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
}
.popup .popup_inner .popup-contact {
  margin: 2rem 0;
  height: 28px;
  width: 100%;
  text-align: center;
  color: #444b68;
  font-family: Roboto;
  font-size: 24px;
  line-height: 28px;
}
.popup .popup_inner .close-icon {
  position: absolute;
  margin: 1rem 0rem 0rem 20.5rem;
}

.contact-method {
  margin: 0 0 1rem 2rem;
  color: #444b68;
  font-family: Roboto;
  font-size: 18px;
  line-height: 24px;
}

.tracking-container {
  position: absolute;
  padding-left: 20px;
  width: calc(100% - 20px);
  text-align: center;
  color: white;
  z-index: 10000;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 19px;
  text-transform: uppercase;
  text-shadow: 1px 1px 10px rgb(28, 43, 76);
}
.tracking-container .icon-ride-tracking {
  position: relative;
  background: rgba(30, 45, 77, 0.8);
  top: 10px;
  padding: 7px;
  border-radius: 6px;
}
.tracking-container .tracking-text {
  background: rgba(30, 45, 77, 0.8);
  padding: 5px 0;
  border-radius: 6px;
}

.info {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.info .popup_inner:before {
  content: "";
  position: absolute;
  right: 11%;
  top: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 12px;
  border-color: transparent transparent #444b68 transparent;
  z-index: 99999;
}
.info .popup_inner {
  position: absolute;
  padding: 1rem;
  height: 7rem;
  width: 25rem;
  top: 60px;
  right: -24px;
  background-color: #444b68;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3), 0 2px 19px 0 rgba(64, 34, 0, 0.3);
  border-radius: 20px;
  z-index: 99998;
}
.info .popup_inner .popup-contact {
  margin-top: 1rem;
  text-align: left;
  color: #ecedef;
  font-size: 14px;
  line-height: 17px;
}
.info .popup_inner .popup-contact .popup-close {
  position: absolute;
  color: #fff;
  margin: -2rem 0rem 0rem 22rem;
}
.redirect--page {
  text-align: center;
  width: 100%;
  height: 94vh;
}
.redirect--page .redirect--title {
  width: 100%;
  color: #444b68;
  font-family: Roboto;
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  display: inline-block;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.redirect--page .message {
  color: #444b68;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 1rem;
}
.button.button--secondary {
  width: auto;
  text-align: center;
}
.invoice-modal .popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999999;
}
.invoice-modal .popup .popupInvoice {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: white;
  border-radius: 20px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
  padding: 2rem;
}
.invoice-modal .popup .popupInvoice .popup-close {
  position: absolute;
  right: 0.5rem;
  top: 0.5em;
  padding: 1rem;
  cursor: pointer;
}
.invoice-modal .popup .popupInvoice .popup-body {
  width: 100%;
  font-size: 24px;
  line-height: 24px;
}
.invoice-modal .popup .popupInvoice .popup-body h3 {
  -webkit-margin-before: unset;
          margin-block-start: unset;
  font-size: 24px;
  font-weight: normal;
}
.invoice-modal .popup .popupInvoice .popup-body p {
  font-size: 18px;
}
.invoice-modal .popup .popupInvoice .popup-body p small {
  color: #7c8195;
  font-size: 14px;
  line-height: 16px;
}
.invoice-modal .popup .popupInvoice .popup-body p small a {
  color: #7c8195;
  cursor: default;
}
.invoice-modal .popup .popupInvoice .popup-body p span {
  font-weight: 500;
}
.invoice-modal .popup .popupInvoice .popup-actions {
  text-align: right;
}
.invoice-modal .popup .popupInvoice .popup-actions .loadingButton,
.invoice-modal .popup .popupInvoice .popup-actions .submit {
  margin-bottom: 0;
  display: inline-block;
}

.contact-method {
  margin: 0 0 1rem 2rem;
  color: #444b68;
  font-family: Roboto;
  font-size: 18px;
  line-height: 24px;
}
.request-apps {
  text-align: center;
  color: #444b68;
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
}
.request-apps .header {
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  margin-top: 3rem;
  margin-bottom: 1rem;
  text-align: left;
}
.request-apps .help {
  text-align: left;
  width: 90%;
  font-size: 24px;
  line-height: 28px;
  margin-top: none;
  margin-bottom: 2rem;
}
.request-apps .cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.request-apps .cards .card {
  height: 524px;
  width: 360px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 20px 40px 0 #e0eaf4;
  margin: 1rem;
  position: relative;
  font-family: Roboto, sans-serif;
}
.request-apps .cards .card .card-header {
  width: 90%;
  color: #444b68;
  font-size: 30px;
  line-height: 35px;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}
.request-apps .cards .card .invite {
  color: #7c8195;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  margin-bottom: 1rem;
}
.request-apps .cards .card .invite .arrow {
  padding-left: 5px;
}
.request-apps .cards .card .phone {
  height: 76.75%;
}
.request-apps .cards .card:hover {
  cursor: pointer;
  box-shadow: 0px 2px 40px 0 rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 990px) {
  .request-apps .cards {
    flex-direction: column;
  }
}

.phone-image {
  height: 100%;
  width: 209.06px;
}
.request-apps .back {
  display: flex;
  color: #444b68;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  text-align: left;
}
.request-apps .back .arrow {
  padding-right: 5px;
}
.request-apps .header {
  display: flex;
  color: #444b68;
  font-family: Roboto, sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  margin-bottom: 1rem;
  text-align: left;
}
.request-apps .content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}
.request-apps .content .table {
  width: 50%;
  height: 100%;
  padding-right: 2rem;
}
.request-apps .invite-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 27rem;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 20px 40px 0 #e0eaf4;
  margin-left: 2rem;
  padding: 2rem 2rem 0 2rem;
}
.request-apps .invite-card .features {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #444b68;
  font-family: Roboto, sans-serif;
  text-align: left;
  margin-right: 2rem;
}
.request-apps .invite-card .features .features-header {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 2rem;
}
.request-apps .invite-card .features .features-list {
  width: 90%;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16px;
  margin-bottom: 1.5rem;
}
.request-apps .invite-card .features .features-list .features-list-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}
.request-apps .invite-card .features .features-list .features-list-item .features-list-bullet {
  height: 10px;
  min-width: 10px;
  margin-top: 2px;
  background-color: #c6c9d1;
  border-radius: 20px;
  margin-right: 14px;
}
.request-apps .invite-card .features .getApp-btn {
  width: 17rem;
}
.request-apps .invite-card .features .getApp-btn .download-app {
  margin: 0 0.25rem;
}
.request-apps .invite-card .phone {
  height: 100%;
}
.credit-cards-page {
  /* Stripe-related tweaks */
}
.credit-cards-page .action-card-zone {
  width: calc(40% - 5rem);
  overflow: auto;
  position: absolute;
  top: calc(64px + 1px);
  right: 0;
  height: calc(100vh - 52px);
  background: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: height 0.25s linear;
  transition: height 0.25s linear;
}
.credit-cards-page .action-card-zone--past-header {
  height: calc(100vh - 52px);
  top: 0;
  position: fixed;
}
.credit-cards-page .action-card-zone--header-and-footer-visible {
  height: calc(100vh - 64px - 52px);
}
.credit-cards-page .action-card-zone--full-vh {
  height: 100vh;
}
.credit-cards-page .action-card-zone--no-action {
  background: none;
  box-shadow: none;
  position: static;
  position: initial;
  overflow: hidden;
}
.credit-cards-page .page-header {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
}
.credit-cards-page .column {
  display: inline-block;
  text-align: center;
  margin-right: 1rem;
}
.credit-cards-page .column--card {
  width: 45%;
  text-align: left;
}
.credit-cards-page .column--date-added {
  width: 25%;
}
.credit-cards-page .column--actions {
  width: 20%;
  min-width: 106px;
  position: relative;
  margin-right: 0;
}
.credit-cards-page .table-headers-container {
  color: #7c8195;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 1.5rem 1rem 1.5rem;
}
.credit-cards-page .table {
  margin-bottom: 3.5rem;
}
.credit-cards-page .row {
  padding: 1rem 1.5rem;
}
.credit-cards-page .row--expired {
  color: #e45b5b;
}
.credit-cards-page .row:hover {
  outline: 2px solid #dadbe1;
}
.credit-cards-page .row--selected,
.credit-cards-page .row--selected:hover {
  outline: 3px solid #7c8195;
}
.credit-cards-page .row:nth-child(even) {
  background: white;
}
.credit-cards-page .note {
  opacity: 0.7;
  margin-top: 1rem;
}
.credit-cards-page .table-header-icon {
  margin-right: 0.5rem;
  position: relative;
}
.credit-cards-page .table-header-icon--credit-card {
  top: 1px;
}
.credit-cards-page .table-header-icon--date {
  top: 3px;
}
.credit-cards-page .table-header-icon--actions {
  top: 3px;
}
.credit-cards-page .action-column-icon {
  position: relative;
  z-index: 1;
}
.credit-cards-page .action-column-icon:first-of-type {
  margin-right: 2.5rem;
}
.credit-cards-page .cc-table-and-actions {
  display: flex;
}
.credit-cards-page .cc-table-and-actions.u-hide {
  display: none;
}
.credit-cards-page .credit-card-tables-container {
  width: 60%;
  margin-right: 5rem;
}
.credit-cards-page .icon-container {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 54px;
}
.credit-cards-page .icon-container:hover {
  z-index: 1;
  background: #dadbe1;
  border-radius: 50%;
  -webkit-transition: background-color 0.25s ease;
  transition: background-color 0.25s ease;
}
.credit-cards-page .icon-container:active {
  background-color: #c6c9d1;
}
.credit-cards-page .position-action-icons {
  position: absolute;
  top: -30px;
  width: 100%;
}
.credit-cards-page .label--small {
  vertical-align: middle;
}
.credit-cards-page .label--visa {
  background: #1a1f71;
}
.credit-cards-page .label--mastercard {
  background: #f79e1b;
}
.credit-cards-page .label--discover {
  background: #ff6000;
}
.credit-cards-page .label--amex {
  background: #0061b6;
}
.credit-cards-page .label--expired {
  background-color: #e45b5b;
}
.credit-cards-page .separator {
  margin: 0 10px;
  color: #c6c9d1;
}
.credit-cards-page .cards-illustration {
  position: relative;
  width: 100%;
  height: 430px;
  background: url(/static/media/cards-illustration.1f3d6cc0.png) center no-repeat;
  background-size: contain;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.credit-cards-page .cards-illustration--behind-table {
  position: relative;
  top: 2rem;
}
@media (min-height: 970px) {
  .credit-cards-page .action-card-zone {
    height: 500px;
    right: 30px;
    position: fixed;
    top: auto;
    height: auto;
    overflow: hidden;
    background: none;
    box-shadow: none;
  }
  .credit-cards-page .action-card-zone--no-action {
    position: static;
    position: initial;
  }
}
.credit-cards-page .StripeElement {
  padding: 10px 0;
  border-bottom: 2px solid #a1a5b3;
}
.credit-cards-page .StripeElement:focus-within {
  border-bottom: 2px solid #444b68;
}
@media (min-width: 1450px) {
  .credit-cards-page .column--card {
    width: 35%;
  }
  .credit-cards-page .column--date-added {
    width: 40%;
  }
}
.action-card {
  padding: 40px;
  position: relative;
  background: white;
}
.action-card .select-container {
  position: relative;
}
.action-card select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #e2e4e8;
  border: none;
  font-size: 18px;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  position: relative;
  width: 100%;
  color: #444b68;
  margin-top: 10px;
}
.action-card select:focus {
  background: #d7d9df;
}
.action-card select::-ms-expand {
  display: none;
}
.action-card select.placeholder-text {
  font-style: italic;
  color: #7c8195;
}
.action-card .select-arrow {
  position: absolute;
  right: 13px;
  top: 26px;
}
.action-card .error {
  text-align: right;
}
.action-card .input-lockup .credit-card-info-lockup {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #a1a5b3;
}
.action-card .input-lockup .credit-card-info-lockup .credit-card-expiration-lockup input {
  border: none;
  width: 2ch;
}
.action-card .input-lockup .credit-card-info-lockup .credit-card-expiration-lockup span {
  margin-left: 3px;
}
.action-card .input-lockup .credit-card-info-lockup:focus-within {
  border-color: #444b68;
}
.action-card .credit-card-type-and-last-four {
  color: #444b68;
  opacity: 0.6;
}
.action-card .saved-and-disabled-text {
  color: #a1a5b3;
  font-size: 18px;
  padding-top: 10px;
}
.action-card .icon-container {
  float: right;
  text-align: center;
  margin-top: -30px;
  margin-right: -30px;
}

@media (min-height: 970px) {
  .action-card {
    position: relative;
    border-radius: 20px;
    margin-right: 26%;
    box-shadow: 0 20px 40px 0 #e0eaf4;
  }
}
.pickupHeader .recommendation {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  color: #7c8195;
  line-height: 23px;
  margin-bottom: 3rem;
  width: 80%;
}
.pickupHeader .header2 {
  font-size: 30px;
  margin-bottom: 1rem;
}
.icon-and-input-lockup {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  height: 4rem;
  width: 100%;
  margin-bottom: 3rem;
  color: #7c8195;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: left;
}
.icon-and-input-lockup .input-lockup {
  margin-bottom: unset;
}
.icon-and-input-lockup .icon {
  height: 60px;
  width: 60px;
  padding-right: 2rem;
  background-repeat: no-repeat;
}
.icon-and-input-lockup .icon--pin {
  background-image: url(/static/media/pin.379d003a.svg);
}
.icon-and-input-lockup .icon--clock {
  background-image: url(/static/media/clock.ee3ae5d6.svg);
}
.icon-and-input-lockup .input-lockup__label {
  width: 28.1px;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.14px;
  line-height: 19px;
  text-align: center;
  text-shadow: 0 10px 10px 0 #1c2b4c;
  margin: 18px 0 0 17px;
}
.form {
  width: 100%;
}
.ruleOfThumb {
  font-size: 14px;
  box-shadow: 0 20px 40px 0 #e0eaf4;
  border-radius: 20px;
  background-color: #ffffff;
  background-image: url(/static/media/image-wing@2x.d5f05402.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 30px;
  padding: 3rem 2rem 2rem 2rem;
  width: 25%;
  line-height: 17px;
  max-width: 20rem;
}
.ruleOfThumb .content {
  color: #7c8195;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}
.ruleOfThumb .description {
  margin-bottom: 1.5rem;
}
.pickupCalcInfo {
  color: #444b68;
  font-family: Roboto, sans-serif;
}
.pickupCalcInfo .v-rule {
  position: absolute;
  height: 35rem;
  width: 2px;
  background-color: #eaeff6;
  margin: 0 2rem 0 2rem;
  margin-top: -3rem;
}
.pickupCalcInfo .header {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 2rem;
}
.pickupCalcInfo .calc-info {
  position: relative;
  width: auto;
  margin: 0 0 0 4rem;
}
.pickupCalcInfo .calc-info-type {
  color: #7c8195;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16px;
  margin-bottom: 0.5rem;
}
.pickupCalcInfo .calc-data {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 2rem;
}
.pickup-page .row-calc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  margin-bottom: 3rem;
}
.pickup-page .subhead {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 1rem;
  width: 80%;
}
.pickup-page .card {
  display: flex;
  flex-direction: row;
  width: calc(45% - 2rem);
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 20px 40px 0 #e0eaf4;
  padding: 3rem;
  margin-right: 2rem;
}
.pickup-page .card .subhead {
  width: 100%;
  margin-bottom: 2rem;
}
.pickup-page .card-header {
  font-size: 18px;
  margin-bottom: 2rem;
  line-height: 23px;
}
.pickup-page .calc {
  width: calc(75% - 2rem);
}
.pickup-page .right {
  width: 66%;
}
.pickup-page .map-container {
  width: 100%;
  min-height: 500px;
  height: calc(100vh - 940px - 52px);
}
.password-page {
  text-align: center;
  width: 100%;
  height: 100%;
}
.password-page .password--card {
  width: 30rem;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 20px 40px 0 #e0eaf4;
  padding: 46px 48px;
  margin: 0 auto 2rem auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.password-page .password--card .card-header-text {
  color: #444b68;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  margin-bottom: 2rem;
}
.header-nav {
  width: 100%;
  height: 4rem;
  padding: 0 10%;
  box-sizing: border-box;
  background: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  font-family: Roboto, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.u-margin-bottom-large {
  margin-bottom: 3rem;
}

.header-gradient {
  width: 100%;
  height: 3rem;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(244, 245, 249, 0)), to(rgb(225, 227, 237)));
  background: linear-gradient(to top, rgba(244, 245, 249, 0), rgb(225, 227, 237));
}

.left-nav .logo {
  max-height: 2em;
  position: relative;
  top: 3px;
}

ul.right-nav {
  margin: 0;
  padding: 0;
}

ul.right-nav li {
  display: inline-block;
  padding: 0 1em;
  margin-top: 1.5em;
}

ul.right-nav li a {
  font-size: 0.75em;
  font-weight: bold;
  text-decoration: none;
  opacity: 0.7;
  font-size: 15px;
  font-weight: 500;
  color: #444b68;
  font-family: Roboto, sans-serif;
}

ul.right-nav li a:hover,
ul.right-nav li:hover {
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.header-text {
  opacity: 0.7;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  color: #444b68;
  font-family: Roboto, sans-serif;
  line-height: 18px;
  cursor: pointer;
}
.header-text .down-arrow {
  margin-left: 0.75rem;
}

.current-page {
  height: 5px;
  border-radius: 2px;
  margin-top: 16px;
}

.current-page-active {
  height: 5px;
  border-radius: 2px;
  background: #444b68;
  margin-top: 16px;
}

.dropdown {
  position: absolute;
  padding: 0.25rem 0;
  top: 50px;
  right: 10px;
  border: 1px solid #e8ecf5;
  background: #fff;
  z-index: 99999;
  border-radius: 6px;
  box-shadow: 0 10px 30px 0 rgba(43, 49, 74, 0.1);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.dropdown .action-link {
  display: block;
  color: rgba(68, 75, 104, 0.7);
  font-size: 15px;
  font-weight: 500;
  padding: 0.5rem 1rem;
}
.dropdown .action-link:hover {
  background: #444b68;
  color: white;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.dropdown .action-link:active {
  background: #262d47;
}
html {
  color: #444b68;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f5f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* GOOGLE MAPS google branding */
a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

/* .gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
} */
.main-content {
  margin: 0 10% 2rem 10%;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  z-index: 1;
}
.footer .icon {
  padding-right: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
  top: 3px;
}

#root {
  display: flex;
  flex-direction: column;
}

a:link {
  text-decoration: none;
  color: #444b68;
}

.attribute-text {
  opacity: 0.7;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: unset;
  text-align: bottom;
  text-transform: uppercase;
  font-weight: 400;
}

.page-header {
  margin-bottom: 3rem;
  font-size: 36px;
  text-align: left;
  font-weight: 500;
}

.page-header--h2 {
  font-size: 30px;
  margin-bottom: 2rem;
  font-weight: 400;
}

.page-header--home-promo {
  text-align: center;
  margin-bottom: 1.5rem;
}

button,
a.button-primary,
div.button-primary,
.button {
  background: #444b68;
  color: white;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0.34px;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

button:focus,
.button:focus {
  background: #262d47;
}

button:focus,
.button:focus,
.button-text-only:focus {
  outline: none;
}

.button--secondary {
  padding: 0.5em 1em;
  font-size: 14px;
}

.button-primary--disabled,
.button--tertiary--disabled,
button:disabled {
  background-color: rgba(68, 75, 104, 0.5);
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  cursor: auto;
}

button:not(:disabled):hover,
a.button-primary:hover,
div.button-primary:hover,
.button:hover {
  background: #262d47;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.button-primary--loading {
  width: 100%;
}

.button--full-width {
  width: 100%;
}

.button--tertiary {
  padding: 0.5rem 0.75rem;
}

.button-text-only {
  text-transform: uppercase;
  font-size: 14px;
  color: #444b68;
  background: white;
  cursor: pointer;
  margin-right: 0.5rem;
  border: none;
  border: 1px solid transparent;
}

button.button-text-only:hover,
button.button-text-only:focus {
  background: #ecedef;
  border: 1px solid #ecedef;
  color: #262d47;
}

.button-row {
  text-align: right;
}

.label {
  color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  background: #444b68;
  margin-right: 0.5rem;
  padding: 3px 7px;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.label--small {
  padding: 2px 5px;
  font-size: 12px;
}

.content-above-footer {
  min-height: calc(100vh - 52px - 4rem - 3rem);
}

.Dashboard.content-above-footer {
  min-height: calc(100vh - 52px - 4rem);
}

.pickup-page .main-content {
  margin-bottom: 0;
}

.u-hide {
  display: none;
}

.u-margin-right-m {
  margin-right: 1rem;
}

.u-margin-right-s {
  margin-right: 0.5rem;
}

.u-margin-bottom-xs {
  margin-bottom: 0.25rem;
}

.u-margin-bottom-m {
  margin-bottom: 1rem;
}

.u-margin-bottom-l {
  margin-bottom: 2rem;
}

a.u-underline {
  text-decoration: underline;
}

.u-underline:visited {
  color: #444b68;
}

.u-center-text {
  text-align: center;
}

.error {
  color: #e45b5b;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 1px;
  line-height: 18px;
  margin-top: 2rem;
  text-align: left;
}

.error--under-input {
  margin-top: 0.5rem;
}

ul.error {
  padding-left: 17px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .pickup-page .icon-and-input-lockup input::-webkit-input-placeholder {
    color: #7c8195;
    font-style: italic;
  }
  .pickup-page .icon-and-input-lockup input:-ms-input-placeholder {
    color: #7c8195;
    font-style: italic;
  }
  .pickup-page .icon-and-input-lockup input::-ms-input-placeholder {
    color: #7c8195;
    font-style: italic;
  }
  .pickup-page .icon-and-input-lockup input::placeholder {
    color: #7c8195;
    font-style: italic;
  }
  .gm-style img[src$=".svg"] {
    width: 35px !important;
    height: 54px !important;
  }
  select::-ms-expand {
    display: none;
  }
  select {
    border-radius: 6px;
  }
  option {
    font-style: normal;
    color: #444b68;
  }
  option:hover {
    background: #444b68;
    color: white;
  }
  option:disabled {
    background: none;
  }
  .circle-loader--large {
    left: 0;
  }
  .ReactTable {
    display: block;
  }
  .notes-content {
    max-height: 72px;
  }
}
