$primary-blue: #444b68;
// $primary-blue: red;

.header-nav {
  width: 100%;
  height: 4rem;
  padding: 0 10%;
  box-sizing: border-box;
  background: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  font-family: Roboto, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.u-margin-bottom-large {
  margin-bottom: 3rem;
}

.header-gradient {
  width: 100%;
  height: 3rem;
  background: linear-gradient(to top, rgba(244, 245, 249, 0), rgba(225, 227, 237, 1));
}

.left-nav .logo {
  max-height: 2em;
  position: relative;
  top: 3px;
}

ul.right-nav {
  margin: 0;
  padding: 0;
}

ul.right-nav li {
  display: inline-block;
  padding: 0 1em;
  margin-top: 1.5em;
}

ul.right-nav li a {
  font-size: 0.75em;
  font-weight: bold;
  text-decoration: none;
  opacity: 0.7;
  font-size: 15px;
  font-weight: 500;
  color: $primary-blue;
  font-family: Roboto, sans-serif;
}

ul.right-nav li a:hover,
ul.right-nav li:hover {
  opacity: 1;
  transition: all 0.5s ease;
}

.header-text {
  opacity: 0.7;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  color: $primary-blue;
  font-family: Roboto, sans-serif;
  line-height: 18px;
  cursor: pointer;

  .down-arrow {
    margin-left: 0.75rem;
  }
}

.current-page {
  height: 5px;
  border-radius: 2px;
  margin-top: 16px;
}

.current-page-active {
  height: 5px;
  border-radius: 2px;
  background: $primary-blue;
  margin-top: 16px;
}

.dropdown {
  position: absolute;
  padding: 0.25rem 0;
  top: 50px;
  right: 10px;
  border: 1px solid #e8ecf5;
  background: #fff;
  z-index: 99999;
  border-radius: 6px;
  box-shadow: 0 10px 30px 0 rgba(43, 49, 74, 0.1);
  transition: all 0.5s ease;

  .action-link {
    display: block;
    color: rgba(68, 75, 104, 0.7);
    font-size: 15px;
    font-weight: 500;
    padding: 0.5rem 1rem;
  }

  .action-link:hover {
    background: $primary-blue;
    color: white;
    transition: all 0.2s ease;
  }

  .action-link:active {
    background: #262d47;
  }
}
