.ruleOfThumb {
  font-size: 14px;
  box-shadow: 0 20px 40px 0 #e0eaf4;
  border-radius: 20px;
  background-color: #ffffff;
  background-image: url('../components/images/image-wing@2x.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 30px;
  padding: 3rem 2rem 2rem 2rem;
  width: 25%;
  line-height: 17px;
  max-width: 20rem;

  .content {
    color: #7c8195;
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
  }

  .description {
    margin-bottom: 1.5rem;
  }
}
