.reservations-tabs {
  height: 100%;
  width: 100%;
  color: #444b68;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-top: 18px;
}

.header-row-container {
  display: flex;
  color: #444b68;
  font-family: Roboto, sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  margin-bottom: 3rem;
  text-align: left;
  width: auto;
  align-items: baseline;
}

.styleSelect select {
  width: 176px;
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  border: 0;
  border-radius: 0;
  height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.styleSelect {
  color: #7c8195;
  overflow: hidden;
  background: url('../components/table_components/table_pagination/down.svg') no-repeat right;
}

.line-sort {
  height: 2px;
  width: 175px;
  border-radius: 1px;
  background-color: #a1a5b3;
  margin-left: 55px;
  position: relative;
  z-index: 2;
}

.status-dropdown {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  flex-direction: column-reverse;
  width: auto;
  margin-left: auto;
}

.status-section {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}
