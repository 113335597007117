@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */

  // PICKUP CALC PAGE
  .pickup-page {
    .icon-and-input-lockup {
      input::placeholder {
        color: #7c8195;
        font-style: italic;
      }
    }
  }

  // Brings map markers into view.
  .gm-style img[src$='.svg'] {
    width: 35px !important;
    height: 54px !important;
  }

  // Hides dropdown arrow.
  select::-ms-expand {
    display: none;
  }

  select {
    border-radius: 6px;
  }

  option {
    font-style: normal;
    color: #444b68;
  }

  option:hover {
    background: #444b68;
    color: white;
  }

  option:disabled {
    background: none;
  }

  // Centers large loading indicator.
  .circle-loader--large {
    left: 0;
  }

  .ReactTable {
    display: block;
  }

  .notes-content {
    max-height: calc(45px + 27px);
    // Above is the offset + height of the notes-gradient.
  }
}
