.redirect--page {
  text-align: center;
  width: 100%;
  height: 94vh;

  .redirect--title {
    width: 100%;
    color: #444b68;
    font-family: Roboto;
    font-size: 36px;
    font-weight: 500;
    line-height: 42px;
    display: inline-block;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .message {
    color: #444b68;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 1rem;
  }
}
