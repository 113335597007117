$footer-height: 52px;
$header-height: 64px;

.credit-cards-page {
  .action-card-zone {
    width: calc(40% - 5rem); // 5rem = Padding between sections.
    overflow: auto;
    position: absolute;
    top: calc(#{$header-height} + 1px);
    right: 0;
    height: calc(100vh - #{$footer-height});
    background: white; // Fills bottom white space.
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    transition: height 0.25s linear;
  }

  .action-card-zone--past-header {
    height: calc(100vh - #{$footer-height});
    top: 0;
    position: fixed;
  }

  .action-card-zone--header-and-footer-visible {
    height: calc(100vh - #{$header-height} - #{$footer-height});
  }

  .action-card-zone--full-vh {
    height: 100vh;
  }

  .action-card-zone--no-action {
    background: none;
    box-shadow: none;
    position: initial;
    overflow: hidden;
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
  }

  .column {
    display: inline-block;
    text-align: center;
    margin-right: 1rem;
  }

  .column--card {
    width: 45%;
    text-align: left;
  }

  .column--date-added {
    width: 25%;
  }

  .column--actions {
    width: 20%;
    min-width: 106px;
    position: relative;
    margin-right: 0;
  }

  .table-headers-container {
    color: #7c8195;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 1.5rem 1rem 1.5rem;
  }

  .table {
    margin-bottom: 3.5rem;
  }

  .row {
    padding: 1rem 1.5rem;
  }

  .row--expired {
    color: #e45b5b;
  }

  .row:hover {
    outline: 2px solid #dadbe1;
  }

  .row--selected,
  .row--selected:hover {
    outline: 3px solid #7c8195;
  }

  .row:nth-child(even) {
    background: white;
  }

  .note {
    opacity: 0.7;
    margin-top: 1rem;
  }

  .table-header-icon {
    margin-right: 0.5rem;
    position: relative;
  }

  .table-header-icon--credit-card {
    top: 1px;
  }

  .table-header-icon--date {
    top: 3px;
  }

  .table-header-icon--actions {
    top: 3px;
  }

  .action-column-icon {
    position: relative;
    z-index: 1;
  }

  .action-column-icon:first-of-type {
    margin-right: 2.5rem;
  }

  .cc-table-and-actions {
    display: flex;
  }

  .cc-table-and-actions.u-hide {
    display: none;
  }

  .credit-card-tables-container {
    width: 60%;
    margin-right: 5rem;
  }

  .icon-container {
    display: inline-block;
    height: 50px;
    width: 50px;
    line-height: 54px; // Vertically centers icons.
  }

  .icon-container:hover {
    z-index: 1;
    background: #dadbe1;
    border-radius: 50%;
    transition: background-color 0.25s ease;
  }

  .icon-container:active {
    background-color: #c6c9d1;
  }

  .position-action-icons {
    position: absolute;
    top: -30px;
    width: 100%;
  }

  .label--small {
    vertical-align: middle;
  }

  .label--visa {
    background: #1a1f71;
  }
  .label--mastercard {
    background: #f79e1b;
  }
  .label--discover {
    background: #ff6000;
  }
  .label--amex {
    background: #0061b6;
  }

  .label--expired {
    background-color: #e45b5b;
  }

  .separator {
    margin: 0 10px;
    color: #c6c9d1;
  }

  .cards-illustration {
    position: relative;
    width: 100%;
    height: 430px;
    background: url('./images/cards-illustration.png') center no-repeat;
    background-size: contain;
    transition: all 0.25s linear;
  }

  .cards-illustration--behind-table {
    position: relative;
    top: 2rem;
  }

  @media (min-height: 970px) {
    .action-card-zone {
      height: 500px;
      right: 30px;
      position: fixed;
      top: auto;
      height: auto;
      overflow: hidden;
      background: none;
      box-shadow: none;
    }

    .action-card-zone--no-action {
      position: initial;
    }
  }

  /* Stripe-related tweaks */
  .StripeElement {
    padding: 10px 0;
    border-bottom: 2px solid #a1a5b3;
  }

  .StripeElement:focus-within {
    border-bottom: 2px solid #444b68;
  }

  @media (min-width: 1450px) {
    .column--card {
      width: 35%;
    }

    .column--date-added {
      width: 40%;
    }
  }
}
