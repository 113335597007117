.request-apps {
  text-align: center;
  color: #444b68;
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    font-size: 36px;
    font-weight: 500;
    line-height: 42px;
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: left;
  }

  .help {
    text-align: left;
    width: 90%;
    font-size: 24px;
    line-height: 28px;
    margin-top: none;
    margin-bottom: 2rem;
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .card {
      height: 524px;
      width: 360px;
      border-radius: 8px;
      background-color: #ffffff;
      box-shadow: 0 20px 40px 0 #e0eaf4;
      margin: 1rem;
      position: relative;
      font-family: Roboto, sans-serif;

      .card-header {
        width: 90%;
        color: #444b68;
        font-size: 30px;
        line-height: 35px;
        margin: auto;
        margin-top: 2rem;
        margin-bottom: 1.5rem;
      }

      .invite {
        color: #7c8195;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 16px;
        margin-bottom: 1rem;

        .arrow {
          padding-left: 5px;
        }
      }

      .phone {
        height: 76.75%;
      }
    }

    .card:hover {
      cursor: pointer;
      box-shadow: 0px 2px 40px 0 rgba(0, 0, 0, 0.25);
    }
  }

  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 990px) {
    .cards {
      flex-direction: column;
    }
  }
}

.phone-image {
  height: 100%;
  width: 209.06px;
}
