@import url('https://fonts.googleapis.com/css?family=Roboto');

.password-page {
  text-align: center;
  width: 100%;
  height: 100%;

  .password--card {
    width: 30rem;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 20px 40px 0 #e0eaf4;
    padding: 46px 48px;
    margin: 0 auto 2rem auto;
    height: fit-content;

    .card-header-text {
      color: #444b68;
      font-size: 24px;
      line-height: 28px;
      text-align: left;
      margin-bottom: 2rem;
    }
  }
}
