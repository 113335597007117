.invite-table .ReactTable {
  .rt-table {
    width: 100%;
    overflow-x: hidden;
    .rt-thead {
      /* column header row */
      .-header {
        width: auto;
        margin-bottom: 0rem;
        padding: 0;
      }

      /* Table Header Column sort area */
      .rt-tr {
        margin: 0;
        text-align: left;
      }
    }

    /* Individual TABLE ITEMS */
    .rt-tbody {
      /* Row styling will revert to default w/o */
      .rt-tr-group {
        overflow-x: hidden;
        height: 54px;
        width: auto;
        opacity: 0.5;
        border-radius: 2px;
        background-color: #ffffff;
        border: none;
        box-shadow: none;
        padding: 0;
        flex-direction: row;
        align-items: center;
        text-align: left;
        margin: 0;
        border-bottom: none;

        /* Individual TABLE ROWS */
        .-even {
          border-radius: 2px;
          border: none;
          height: 54px;
          border-radius: 2px;
          background: #f4f5f9;
          flex-direction: row;
          align-items: center;
          text-align: left;
          padding: 0 1rem;
        }

        .-odd {
          background: #ffffff;
          border-radius: 2px;
          border-bottom: none;
          padding: 0 1rem;
        }
      }
    }
  }
  .pagination-bottom {
    .pagination-section-all {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex;
      margin-left: 0;
      width: 100%;
    }
    .pagination-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
    }
  }
}

/* Individual TABLE ITEMS Hover action */
.invite-table .ReactTable .rt-tbody .rt-tr-group:hover {
  cursor: pointer;
  box-shadow: none;
  outline: 2px solid #dadbe1;
}
