.overview {
  font-size: 18px;
  line-height: 24px;
  margin-right: 2rem;
  min-width: 50px;
  width: 45%;

  .details {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;

    .detail-icon {
      margin-right: 1rem;
      min-width: 60px;
      height: 60px; // For IE.
      width: 60px; // For IE.
    }

    .overview__chauffeur-avatar-container {
      width: 60px;
      min-width: 60px;
      height: 60px;
      overflow: hidden;
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border: 2px solid #444b68;
      background: #f4f5f9;
    }

    .overview__chauffeur-avatar {
      height: auto;
      min-width: 100%;
      min-height: 100%;
      width: 100%;
    }

    .detail {
      .detail-header {
        color: #7c8195;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 16px;
      }

      .detail-content {
        color: #444b68;
        font-family: Roboto;
      }

      .detail-extra {
        color: #a1a5b3;
        font-family: Roboto;
        font-size: 14px;
        font-style: italic;
        letter-spacing: 1px;
        line-height: 16px;
      }
    }
  }

  .details--top-align {
    align-items: start;
  }

  .favorite-icon {
    margin-right: 0.25rem;
  }

  .favorite-icon--favorited g {
    fill: #ffbb6f;
  }

  .favorite-button-hover-container {
    background: #ecedef;
    font-size: 14px;
    line-height: 18px;
    position: absolute;
    top: 2rem;
    width: 16rem;
    z-index: 3;
    padding: 0.5rem;
    border-radius: 6px;
    box-shadow: 0 10px 20px 0 #c0c2c8;
  }
}
