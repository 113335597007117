.action-card {
  padding: 40px;
  position: relative;
  background: white;

  .select-container {
    position: relative;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #e2e4e8;
    border: none;
    font-size: 18px;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    position: relative;
    width: 100%;
    color: #444b68;
    margin-top: 10px; // Added since "label" wouldn't honor bottom margin.
  }

  select:focus {
    background: #d7d9df;
  }

  // Hides dropdown arrow on IE.
  select::-ms-expand {
    display: none;
  }

  select.placeholder-text {
    font-style: italic;
    color: #7c8195;
  }

  .select-arrow {
    position: absolute;
    right: 13px;
    top: 26px;
  }

  .error {
    text-align: right;
  }

  .input-lockup .credit-card-info-lockup {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #a1a5b3;

    .credit-card-expiration-lockup {
      input {
        border: none;
        width: 2ch;
      }

      span {
        margin-left: 3px;
      }
    }
  }

  .input-lockup .credit-card-info-lockup:focus-within {
    border-color: #444b68;
  }

  .credit-card-type-and-last-four {
    color: #444b68;
    opacity: 0.6;
  }

  .saved-and-disabled-text {
    color: #a1a5b3;
    font-size: 18px;
    padding-top: 10px;
  }

  .icon-container {
    float: right;
    text-align: center;
    margin-top: -30px;
    margin-right: -30px;
  }
}

@media (min-height: 970px) {
  .action-card {
    position: relative;
    border-radius: 20px;
    margin-right: 26%;
    box-shadow: 0 20px 40px 0 #e0eaf4;
  }
}
