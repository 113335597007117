.ride-content {
  display: flex;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 20px 40px 0 #e0eaf4;
  overflow: hidden;
  position: relative;

  .ride-content-left {
    padding: 2rem 0.75rem 2rem 3rem;
    flex: 2 1 auto;
    width: 45%;

    .note {
      display: flex;
      background: rgba($color: #ffbb6f, $alpha: 0.25);
      border-radius: 12px;
      padding: 1rem;
      margin-bottom: 2rem;
      font-size: 14px;
      line-height: 17px;

      .note-icon {
        color: #444b68;
        height: 2rem;
        width: 2rem;
        margin-right: 1rem;
      }
    }

    .ride-info {
      display: flex;
    }
  }

  .map-toggle {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
    left: 20px;
    cursor: pointer;

    .map-toggle__half {
      display: flex;
      background-color: white;
      height: 50%;
      width: 20px;
      flex: 2 1 auto;
    }

    .map-toggle__half--top {
      margin-bottom: -20px;
      flex-direction: column-reverse;
    }

    .map-toggle__half--bottom {
      margin-top: -20px;
    }

    .map-toggle__handle {
      margin-left: 0.15rem;
      height: 63px;
      width: 4px;
      border-radius: 2px;
      background-color: #c6c9d1;
      z-index: 1;
    }

    .map-toggle__arrow-cutout {
      border-color: transparent white;
      border-style: solid;
      border-width: 20px 0px 20px 20px;
    }
  }

  .subhead {
    font-size: 24px;
  }

  .ride-details-show {
    width: 44px;
    z-index: 999999;
    cursor: pointer;
    height: calc(100vh - 330px); // 330 = Combined height of everything above & below map.
    min-height: 400px;
    position: relative;

    .vertical-text {
      position: relative;
      top: 50%;
      transform-origin: right;
      transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      color: #7c8195;
      font-size: 14px;
      letter-spacing: 1px;
      white-space: nowrap;
    }

    .arrow {
      position: absolute;
      right: -20px; // Border width.
      top: 50%;
      transform: translateY(-50%);
      border-color: transparent white;
      border-style: solid;
      border-width: 20px 0px 20px 20px;
    }
  }

  .Map {
    position: relative;
    flex: 2 1 auto;
    width: 40%;
    z-index: 1;
  }
}

.ride-extras {
  display: flex;
  flex-direction: row;

  .item-of-choice {
    width: 50%;
    border-radius: 20px;
    background-color: #ffffff;
    margin-right: 2rem;
    padding: 2rem 3rem;

    .item-of-choice-header {
      color: #444b68;
      font-family: Roboto;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 2rem;
    }

    .item-of-choice-content {
      display: flex;
      flex-direction: row;

      .item-of-choice-input {
        width: 30rem;
        color: #444b68;
        font-family: Roboto;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 2rem;
        margin-right: 5rem;
      }

      .item-of-choice-status {
        color: #444b68;
        font-family: Roboto;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 2rem;

        .request-status {
          color: #7c8195;
          font-family: Roboto;
          font-size: 14px;
          letter-spacing: 1px;
          line-height: 16px;
          margin-bottom: 1rem;
        }

        .stage {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-bottom: 1rem;
          color: #444b68;
          font-family: Roboto;
          font-size: 14px;
          letter-spacing: 1px;
          line-height: 23.5px;

          .status-icon {
            box-sizing: border-box;
            height: 23.5px;
            width: 23.5px;
            border-radius: 20px;
            margin-right: 0.75rem;
          }
        }
      }
    }

    .item-of-choice-contact {
      color: #a1a5b3;
      font-family: Roboto;
      font-size: 14px;
      line-height: 16px;

      .handcrafting-desk {
        color: #444b68;
        text-decoration: underline;
      }
    }

    .item-request-btn {
      position: relative;

      .btn-enabled {
        height: 42.62px;
        width: 10rem;
        border-radius: 6px;
        background-color: #444b68;
      }

      .btn-disabled {
        height: 42.62px;
        width: 10rem;
        border-radius: 6px;
        background-color: rgba(68, 75, 104, 0.5);
      }

      .btn {
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.34px;
        line-height: 19px;
      }
    }
  }

  .check-in {
    height: 352px;
    width: 40%;
    border-radius: 20px;
    background-color: #ffffff;
    padding: 2rem 3rem;
    position: relative;

    .info-icon {
      position: relative;
      top: -150px;
      right: -100%;
    }

    .check-in-header {
      height: 28px;
      width: 156px;
      color: #444b68;
      font-family: Roboto;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 2rem;
    }

    .check-in-status {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 1rem;
      color: #444b68;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 23.5px;

      .status-icon {
        margin-right: 0.75rem;
      }
    }

    .check-in-message {
      color: #444b68;
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.security-icon {
  height: 63.52px;
  width: 63.76px;
  margin-right: 1rem;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999999;

  .popup_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    height: 188px;
    width: 363px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);

    .popup-contact {
      margin: 2rem 0;
      height: 28px;
      width: 100%;
      text-align: center;
      color: #444b68;
      font-family: Roboto;
      font-size: 24px;
      line-height: 28px;
    }

    .close-icon {
      position: absolute;
      margin: 1rem 0rem 0rem 20.5rem;
    }
  }
}

.contact-method {
  margin: 0 0 1rem 2rem;
  color: #444b68;
  font-family: Roboto;
  font-size: 18px;
  line-height: 24px;
}

.tracking-container {
  position: absolute;
  padding-left: 20px;
  width: calc(100% - 20px);
  text-align: center;
  color: white;
  z-index: 10000;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 19px;
  text-transform: uppercase;
  text-shadow: 1px 1px 10px rgb(28, 43, 76);

  .icon-ride-tracking {
    position: relative;
    background: rgba(30, 45, 77, 0.8);
    top: 10px;
    padding: 7px;
    border-radius: 6px;
  }

  .tracking-text {
    background: rgba(30, 45, 77, 0.8);
    padding: 5px 0;
    border-radius: 6px;
  }
}

.info {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);

  .popup_inner:before {
    content: '';
    position: absolute;
    right: 11%;
    top: -12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 12px;
    border-color: transparent transparent #444b68 transparent;
    z-index: 99999;
  }

  .popup_inner {
    position: absolute;
    padding: 1rem;
    height: 7rem;
    width: 25rem;
    top: 60px;
    right: -24px;
    background-color: #444b68;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3), 0 2px 19px 0 rgba(64, 34, 0, 0.3);
    border-radius: 20px;
    z-index: 99998;

    .popup-contact {
      margin-top: 1rem;
      text-align: left;
      color: #ecedef;
      font-size: 14px;
      line-height: 17px;

      .popup-close {
        position: absolute;
        color: #fff;
        margin: -2rem 0rem 0rem 22rem;
      }
    }
  }
}
