.pickupCalcInfo {
  color: #444b68;
  font-family: Roboto, sans-serif;

  .v-rule {
    position: absolute;
    height: 35rem;
    width: 2px;
    background-color: #eaeff6;
    margin: 0 2rem 0 2rem;
    margin-top: -3rem;
  }

  .header {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 2rem;
  }

  .calc-info {
    position: relative;
    width: auto;
    margin: 0 0 0 4rem;
  }

  .calc-info-type {
    color: #7c8195;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 16px;
    margin-bottom: 0.5rem;
  }

  .calc-data {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 2rem;
  }
}
