.pickup-page {
  .row-calc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
    text-align: left;
    width: 100%;
    margin-bottom: 3rem;
  }

  .subhead {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 1rem;
    width: 80%;
  }

  .card {
    display: flex;
    flex-direction: row;
    width: calc(45% - 2rem);
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 20px 40px 0 #e0eaf4;
    padding: 3rem;
    margin-right: 2rem;

    .subhead {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  .card-header {
    font-size: 18px;
    margin-bottom: 2rem;
    line-height: 23px;
  }

  .calc {
    width: calc(75% - 2rem);
  }

  .right {
    width: 66%;
  }

  .map-container {
    width: 100%;
    min-height: 500px;
    height: calc(100vh - 940px - 52px); // VH minus height of content above map & footer.
  }
}
