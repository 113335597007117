.invoice-table .ReactTable {
  .rt-table {
    width: 100%;

    .rt-thead {
      /* column header row */
      .-header {
        width: 100%;
        margin-bottom: 0rem;
        padding: 0;
      }

      /* Table Header Column sort area */
      .rt-tr {
        text-align: left;
        padding: 0 1.5rem;
        width: calc(100% - 3rem); // For IE. 3rem = Sum of the left and right padding.
      }
    }

    .rt-tr {
      margin: 0;
      width: calc(100% - 3rem); // For IE. 3rem = Sum of the left and right padding.
    }

    /* Individual TABLE ITEMS */
    .rt-tbody {
      /* Row styling will revert to default w/o */
      .rt-tr-group {
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.7);
        border: none;
        box-shadow: none;
        padding: 0;
        flex-direction: row;
        align-items: center;
        text-align: left;
        margin: 0;
        border-bottom: none;
        width: 100%;

        /* Individual TABLE ROWS */
        .-even {
          padding: 1rem 1.5rem;
          border-radius: 2px;
          border: none;
          border-radius: 2px;
          background: #f4f5f9;
          flex-direction: row;
          align-items: center;
          text-align: left;
          margin: 0;
        }

        .-odd {
          padding: 1rem 1.5rem;
          border-radius: 2px;
          border-bottom: none;
          margin: 0;
        }
      }
    }
  }
  .pagination-bottom {
    .pagination-section-all {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex;
      margin-left: 0;
      width: 100%;
    }
    .pagination-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
    }
  }
}

/* Individual TABLE ITEMS Hover action */
.invoice-table .ReactTable .rt-tbody .rt-tr-group:hover {
  cursor: pointer;
  box-shadow: none;
  outline: 2px solid #dadbe1;
  z-index: 2; // For IE.
}
