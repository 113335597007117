@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

$primary-blue: #444b68;

html {
  color: $primary-blue;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f5f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* GOOGLE MAPS google branding */
a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

/* .gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
} */

.main-content {
  margin: 0 10% 2rem 10%;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  z-index: 1;

  .icon {
    padding-right: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
    top: 3px;
  }
}

#root {
  display: flex;
  flex-direction: column;
}

a:link {
  text-decoration: none;
  color: $primary-blue;
}

.attribute-text {
  opacity: 0.7;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: unset;
  text-align: bottom;
  text-transform: uppercase;
  font-weight: 400;
}

.page-header {
  margin-bottom: 3rem;
  font-size: 36px;
  text-align: left;
  font-weight: 500;
}

.page-header--h2 {
  font-size: 30px;
  margin-bottom: 2rem;
  font-weight: 400;
}

.page-header--home-promo {
  text-align: center;
  margin-bottom: 1.5rem;
}

button,
a.button-primary,
div.button-primary,
.button {
  background: $primary-blue;
  color: white;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0.34px;
  transition: all 0.25s linear;
}

button:focus,
.button:focus {
  background: #262d47;
}

button:focus,
.button:focus,
.button-text-only:focus {
  outline: none;
}

.button--secondary {
  padding: 0.5em 1em;
  font-size: 14px;
}

.button-primary--disabled,
.button--tertiary--disabled,
button:disabled {
  background-color: rgba(68, 75, 104, 0.5);
  transition: all 0.25s linear;
  cursor: auto;
}

button:not(:disabled):hover,
a.button-primary:hover,
div.button-primary:hover,
.button:hover {
  background: #262d47;
  transition: all 0.25s linear;
}

.button-primary--loading {
  width: 100%;
}

.button--full-width {
  width: 100%;
}

.button--tertiary {
  padding: 0.5rem 0.75rem;
}

.button-text-only {
  text-transform: uppercase;
  font-size: 14px;
  color: #444b68;
  background: white;
  cursor: pointer;
  margin-right: 0.5rem;
  border: none;
  border: 1px solid transparent;
}

button.button-text-only:hover,
button.button-text-only:focus {
  background: #ecedef;
  border: 1px solid #ecedef;
  color: #262d47;
}

.button-row {
  text-align: right;
}

.label {
  color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  background: $primary-blue;
  margin-right: 0.5rem;
  padding: 3px 7px;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.label--small {
  padding: 2px 5px;
  font-size: 12px;
}

.content-above-footer {
  min-height: calc(100vh - 52px - 4rem - 3rem); // Subtracts height of footer, header, & header gradient.
}

.Dashboard.content-above-footer {
  min-height: calc(100vh - 52px - 4rem); // Subtracts height of footer & header.
}

.pickup-page .main-content {
  margin-bottom: 0;
}

.u-hide {
  display: none;
}

.u-margin-right-m {
  margin-right: 1rem;
}

.u-margin-right-s {
  margin-right: 0.5rem;
}

.u-margin-bottom-xs {
  margin-bottom: 0.25rem;
}

.u-margin-bottom-m {
  margin-bottom: 1rem;
}

.u-margin-bottom-l {
  margin-bottom: 2rem;
}

a.u-underline {
  text-decoration: underline;
}

.u-underline:visited {
  color: $primary-blue;
}

.u-center-text {
  text-align: center;
}

.error {
  color: #e45b5b;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 1px;
  line-height: 18px;
  margin-top: 2rem;
  text-align: left;
}

.error--under-input {
  margin-top: 0.5rem;
}

ul.error {
  padding-left: 17px; // 17px aligns the first bullet with the start of the password.
}
