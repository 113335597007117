$primary-blue: #444b68;

.input-lockup {
  width: 100%;
  color: #7c8195;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: left;
  margin-bottom: 3rem;
  position: relative;

  input,
  textarea {
    width: 100%;
    color: $primary-blue;
    font-size: 18px;
    padding: 10px 0px;
    border: none;
    border-bottom: 2px solid transparent;
  }

  textarea {
    line-height: 24px;
    resize: none;
    font-family: Roboto, sans-serif; // Doesn't consistently keep font without it.
  }

  input.underline,
  textarea.underline {
    border-bottom: 2px solid #a1a5b3;
  }

  input::placeholder,
  textarea::placeholder {
    color: #a1a5b3;
    font-style: italic;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  input:focus,
  textarea:focus {
    border-bottom: 2px solid $primary-blue;
  }

  .passwordIconContainer {
    position: absolute;
    right: 0;
    top: 15px;
    line-height: 58px;
  }
}
