.text-content-collapsed {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;

  .text-gradient {
    position: absolute;
    top: 45px;
    height: 28px;
    width: 100%;
    transform: rotate(180deg);
    border-radius: 2px;
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  }

  .text-gradient--rideNote {
    background: linear-gradient(180deg, #ffeedb 0%, rgba(255, 238, 219, 0) 100%);
  }
}

.text-content {
  height: 100%;
}
