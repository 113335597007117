.request-apps {
  .back {
    display: flex;
    color: #444b68;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: left;
    .arrow {
      padding-right: 5px;
    }
  }

  .header {
    display: flex;
    color: #444b68;
    font-family: Roboto, sans-serif;
    font-size: 36px;
    font-weight: 500;
    line-height: 42px;
    margin-bottom: 1rem;
    text-align: left;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;

    .table {
      width: 50%;
      height: 100%;
      padding-right: 2rem;
    }
  }

  .invite-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 27rem;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 20px 40px 0 #e0eaf4;
    margin-left: 2rem;
    padding: 2rem 2rem 0 2rem;

    .features {
      display: flex;
      flex-direction: column;
      height: 100%;
      color: #444b68;
      font-family: Roboto, sans-serif;
      text-align: left;
      margin-right: 2rem;

      .features-header {
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 2rem;
      }

      .features-list {
        width: 90%;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 16px;
        margin-bottom: 1.5rem;

        .features-list-item {
          display: flex;
          flex-direction: row;
          margin-bottom: 1rem;

          .features-list-bullet {
            height: 10px;
            min-width: 10px;
            margin-top: 2px;
            background-color: #c6c9d1;
            border-radius: 20px;
            margin-right: 14px;
          }
        }
      }

      .getApp-btn {
        width: 17rem;
        .download-app {
          margin: 0 0.25rem;
        }
      }
    }

    .phone {
      height: 100%;
    }
  }
}
