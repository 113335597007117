.rides-back {
  display: flex;
  flex-direction: row;
  color: #444b68;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 2rem;
  text-align: left;

  .arrow {
    padding-right: 0.5rem;
  }
}

.ride-header {
  .ride-header-info {
    margin-bottom: 1rem;
    display: inline-block;

    .ride-title {
      font-size: 36px;
      font-weight: 500;
      line-height: 39px;
    }

    .reservation-status-warning {
      color: #e45b5e;
      font-weight: 600;
      background: none;
    }

    .ride-id-row {
      display: flex;
      margin-bottom: 0.5rem;
      align-items: baseline;

      .reservation-status {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .ride-status {
    top: -4px;
    position: relative;
    display: inline-block;
    align: right;

    .ride-status__container {
      display: grid;
      grid-template-columns: auto 1fr;
      display: inline-block;
    }

    .ride-status__container:first-of-type {
      margin-right: 1rem;
    }

    .ride-status__label-and-status {
      display: inline-block;
    }

    .ride-status__label {
      display: inline-block;
    }

    .ride-status__status {
      display: inline-block;
      font-weight: 300;
    }

    .icon-checkmark {
      margin-right: 4px;
      opacity: 0.7;
      position: relative;
      top: 1px;
    }
  }

  .security-icon {
    height: 63.52px;
    width: 63.76px;
    margin-right: 1rem;
  }
}
