.popupContactFortis {
  color: #444b68;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 20px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
  padding: 4rem;
  width: max-content;
  text-align: center;

  .popup-close {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    padding: 1rem;
  }

  .header {
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    display: block;
    margin-bottom: 1rem;
  }

  .contact {
    display: inline-block;
    font-size: 18px;
  }

  .vertical-rule {
    display: inline-block;
    background: #dadbe1;
    width: 1px;
    height: 50px;
    margin: 0 1.5rem;
    position: relative;
    top: 20px;
  }

  .contact {
    .icon {
      margin-right: 0.5rem;
      width: 25px;
      text-align: center;
    }

    .icon--mail {
      position: relative;
      top: 2px;
    }

    .icon--phone {
      position: relative;
      top: 5px;
    }
  }
}
