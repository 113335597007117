.sorter {
  pointer-events: auto;
  cursor: pointer;
  max-height: 14px;
  max-width: 10px;
  vertical-align: middle;
  padding: 0.5rem;
}

.icon4table {
  margin-right: 0.5rem;
  vertical-align: middle;
  max-height: 24px;
  max-width: 24px;
}

.ride {
  opacity: 0.7;
  color: #444b68;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 16px;
  display: inline-block;
  position: relative;
}

.not-available {
  background: #444b68;
  border-radius: 20px;
  top: 100%;
  height: 1px;
  width: 50px;
}

.status {
  display: flex;
  flex-direction: row;

  .status-indicator {
    height: 10px;
    max-width: 10px;
    margin-top: 5px;
    margin-right: 8px;
    border-radius: 20px;
  }

  .blue {
    background: #4a90e2;
  }
  .red {
    background: #e45b5b;
  }
  .green {
    background: #5ea422;
  }
  .gray {
    background: #a1a5b3;
  }
}

img.icon-ride-tracking-in-table {
  width: 11px;
  padding: 0 2px;
}

.rideId-column-content {
  display: flex;
}
